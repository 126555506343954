body {
  background-color: #333333;
  color: #ffffff;
  padding: 1em;
}

ul, li {
  color: white;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  display: inline-flex;
  padding: 1em;
}

ul {
  a, a:visited, a:hover {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #ff00aa;
  }
}
